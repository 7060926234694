import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from "rxjs";
import { Kanbanboard } from "../interfaces/kanban.interface";
import { KanbanboardLabel } from "../interfaces/kanban-label.interface";
import { TableColumn } from "../../../../@vex/interfaces/table-column.interface";
import icEdit from "@iconify/icons-ic/twotone-edit";
import icDelete from "@iconify/icons-ic/twotone-delete";
import marKunreadMailbox from "@iconify/icons-ic/twotone-markunread-mailbox";
import icLibraryBooks from "@iconify/icons-ic/twotone-library-books";
import icNoteAdd from "@iconify/icons-ic/twotone-note-add";
import icAssessment from "@iconify/icons-ic/twotone-assessment";
import icAssignment from "@iconify/icons-ic/twotone-assignment";
import icMessage from "@iconify/icons-ic/twotone-message";
import icDateRange from "@iconify/icons-ic/twotone-date-range";
import icAccountBalance from "@iconify/icons-ic/twotone-account-balance";
import icPerson from "@iconify/icons-ic/twotone-person";
import icBusiness from "@iconify/icons-ic/twotone-business";
import icAddAlarm from "@iconify/icons-ic/twotone-add-alarm";
import icSend from "@iconify/icons-ic/twotone-send";
import icPayment from "@iconify/icons-ic/twotone-payment";
import icCheck from "@iconify/icons-ic/twotone-check";
import icCloudDownload from "@iconify/icons-ic/twotone-cloud-download";
import icCancel from "@iconify/icons-ic/twotone-cancel";
import icInsertDriveFile from "@iconify/icons-ic/twotone-insert-drive-file";

import { Action } from "../interfaces/action.interface";
import { Link } from "../interfaces/link.interface";
import icStar from "@iconify/icons-ic/twotone-star";
import { StatusList } from "@vought-app/data";

export enum ActionEnum {
  CREATE = 'create',
  UPDATE = 'update',
  CHANGE_STATUS = 'change_status',
  DUPLICATE = 'duplicate',
  QUOTE_LINK = 'quote_link',
  DELETE = 'delete',
  ADD_NOTE = 'add-note',
  ADD_ACTIVITY = 'add-activity',
  RECAP = 'recap',
  SEND_MESSAGE = 'send-message',
  SEND_BDC = 'send-bdc',
  ADD_PAYMENT = 'add-payment',
  VALIDATE_PAYMENT_DATE = 'validate-payment-date',
  DOWNLOAD_BDC = 'download-bdc',
  CANCEL = 'cancel',
  GENERATE_INVOICE = 'generate-invoice',
  SEND_INVOICE = 'send-invoice',
  APPROVE_EXPENSE = 'approve-expense',
  REJECT_EXPENSE = 'reject-expense',
  ENABLE_TENANT = 'enable-tenant',

}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  subject$: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  data$: Observable<any[]> = this.subject$.asObservable();

  /**************************************************************************************************************
   * ********************************************INVOICE DATA***************************************************
   ***************************************************************************************************************
   *
   * /
   /** ORDER DATA TABLE **/

  public invoiceTableColumns: TableColumn<any>[] = [

    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Référence', property: 'invoiceNumber', type: 'text', visible: true }, { label: 'Commande', property: 'orderNumber', type: 'text', visible: true },
    { label: 'Date de création', property: 'createdAt', type: 'date', visible: true },
    { label: 'Echéance', property: 'deadline', type: 'date', visible: true },
    { label: 'Client', property: 'customerName', type: 'text', visible: true },
    { label: 'Commercial', property: 'commercialName', type: 'text', visible: true },
    { label: 'Activités', property: 'activities', type: 'text', visible: false },
    { label: 'Étiquettes', property: 'labels', type: 'labels', visible: true },
    { label: 'Total payé', property: 'totalPaid', type: 'amount', visible: true },
    { label: 'Reste à payer', property: 'leftToPay', type: 'amount', visible: true },
    { label: 'Garantie', property: 'guarantee', type: 'text', visible: true },
    { label: 'Statut de la livraison', property: 'deliveryStatus', type: 'tag', visible: true },
    { label: 'Statut du paiement', property: 'paymentStatus', type: 'tag', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }

  ];


  /** ORDER DATA KANBANBOARD **/
  public invoiceKanbanboards: Kanbanboard[] = [
    {
      id: 1,
      label: 'Mes Factures',
      children: [
        { id: 1, value: 'UNPAID', label: 'EN ATTENTE DE PAIEMENT', children: [] },
        { id: 2, value: 'PARTIAL', label: 'PAIEMENT EN COURS', children: [] },
        { id: 3, value: 'PAID', label: 'PAIEMENT VALIDÉ', children: [] },
      ]
    }
  ];


  public invoiceActions: Action[] = [
    {
      label: 'Générer Facture',
      icon: this.icInsertDriveFile,
      action: ActionEnum.GENERATE_INVOICE,
      list: true,
      grid: false,
      kanban: true
    },
    {
      label: 'Enregistrer une note',
      icon: this.icNoteAdd,
      action: ActionEnum.ADD_NOTE,
      list: true,
      grid: false,
      kanban: true
    },
    {
      label: 'Planifier une activité',
      icon: this.icDateRange,
      action: ActionEnum.ADD_ACTIVITY,
      list: true,
      grid: false,
      kanban: true
    },
    {
      label: 'Envoyer une facture',
      icon: this.icSend,
      action: ActionEnum.SEND_INVOICE,
      list: true,
      grid: false,
      kanban: true
    },
  ];


  /**************************************************************************************************************
   * ********************************************PRODUCT DATA***************************************************
   ***************************************************************************************************************
   *
   * /
   /** ORDER DATA TABLE **/

  public productTableColumns: TableColumn<any>[] = [

    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Référence', property: 'reference', type: 'text', visible: true },
    { label: 'Produit', property: 'name', type: 'text', visible: true },
    { label: 'Date de création', property: 'createdAt', type: 'date', visible: true },
    { label: 'Prix', property: 'price', type: 'amount', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }

  ];

  public productActions: Action[] = [
    { label: 'Modifier', icon: this.icEdit, action: ActionEnum.UPDATE, list: true, grid: true, kanban: false },
    { label: 'Supprimer', icon: this.icDelete, action: ActionEnum.DELETE, list: true, grid: true, kanban: false }
  ];

  /**************************************************************************************************************
   * ********************************************ORDER DATA***************************************************
   ***************************************************************************************************************
   *
   * /
   /** ORDER DATA TABLE **/

  public orderTableColumns: TableColumn<any>[] = [

    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Référence', property: 'reference', type: 'text', visible: true },
    { label: 'Commande', property: 'designation', type: 'text', visible: true },
    { label: 'Date de création', property: 'createdAt', type: 'date', visible: true },
    { label: 'Echéance', property: 'deadline', type: 'date', visible: true },
    { label: 'Client', property: 'customer', type: 'text', visible: true },
    { label: 'Commercial', property: 'commercialId', type: 'text', visible: true },
    { label: 'Total', property: 'subtotalHT', type: 'amount', visible: true },
    { label: 'Statut', property: 'status', type: 'tag', visible: true },
    { label: 'Étiquettes', property: 'tags', type: 'button', visible: false },
    { label: 'Garantie', property: 'guarantee', type: 'text', visible: false },
    { label: 'Feedback', property: 'feedback', type: 'text', visible: false },
    { label: 'Activites', property: 'activities', type: 'text', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }

  ];
  public dataTableStatus: any[] = StatusList;

  public orderDataTableActions = [

    { label: 'Enregistrer une note', icon: this.icNoteAdd, action: ActionEnum.ADD_NOTE, list: true, kanban: true },
    { label: 'Planifier une activité', icon: this.icAddAlarm, action: ActionEnum.ADD_ACTIVITY, list: true, kanban: true },
    { label: 'Modifier', icon: this.icEdit, action: ActionEnum.UPDATE, list: true, kanban: true },
    { label: 'Envoyer un bon de commande', icon: this.icSend, action: ActionEnum.SEND_BDC, list: true, kanban: true },
    {
      label: 'Ajouter un paiement client',
      icon: this.icPayment,
      action: ActionEnum.ADD_PAYMENT,
      list: true,
      kanban: true
    },
    {
      label: 'Valider le changement de date de paiement',
      icon: this.icCheck,
      action: ActionEnum.VALIDATE_PAYMENT_DATE,
      list: true,
      kanban: true
    },
    {
      label: 'Télécharger une copie du BDC',
      icon: this.icCloudDownload,
      action: ActionEnum.DOWNLOAD_BDC,
      list: true,
      kanban: true
    },
    { label: 'Annuler', icon: this.icCancel, action: ActionEnum.CANCEL, list: true, kanban: true }

  ];

  /** ORDER DATA KANBANBOARD **/
  public orderKanbanboards: Kanbanboard[] = [
    {
      id: 1,
      label: 'Mes Pipelines',
      children: [
        { id: 1, value: 'NEW_CMD', label: 'NOUVELLES COMMANDES', children: [] },
        { id: 2, value: 'START_MANUFACTURING', label: 'EN COURS DE PRÉPARATION', children: [] },
        { id: 3, value: 'TRUCK_AFFECTED', label: 'EN COURS DE CHARGEMENT', children: [] },
        { id: 4, value: 'DELIVERY_IN_PROGRESS', label: 'EN COURS DE LIVRAISON', children: [] },
        { id: 5, value: 'DELIVERY_DONE', label: 'LIVRÉE (TOUJOURS IMPAYÉE)', children: [] },
      ]
    }
  ];

  /**************************************************************************************************************
   * ********************************************PIPELINE DATA***************************************************
   ***************************************************************************************************************
   * /
   /** PIPELINE DATA TABLE **/
  public tableColumns: TableColumn<any>[] = [
    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Cree le', property: 'createdAt', type: 'date', visible: false },
    { label: 'Opportunité', property: 'designation', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Client', property: 'companyName', type: 'text', visible: false },
    { label: 'Nom du contact', property: 'contactName', type: 'text', visible: false },
    { label: 'Email', property: 'email', type: 'text', visible: true },
    {
      label: 'Telephone',
      property: 'phoneNumber',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium']
    },
    {
      label: 'Adresse',
      property: 'address',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium']
    },
    { label: 'Zone', property: 'zone', type: 'text', visible: false, cssClasses: ['text-secondary', 'font-medium'] },
    {
      label: 'Commercial',
      property: 'commercialName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium']
    },
    {
      label: 'Activites',
      property: 'activities',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium']
    },
    { label: 'Échéance de mes Activités', property: 'activitiesDeadlineDate', type: 'date', visible: false },
    { label: 'Campagne', property: 'campaign', type: 'text', visible: false },
    { label: 'Moyen', property: 'means', type: 'text', visible: false },
    { label: 'Revenu espéré', property: 'expectedIncome', type: 'amount', visible: false },
    { label: 'Statut', property: 'status', type: 'tag', visible: true },
    { label: 'Probabilité', property: 'probability', type: 'text', visible: false },
    { label: 'Étiquettes', property: 'labels', type: 'labels', visible: false },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }

  ];

  /** PIPELINE KANBANBOARD **/
  public kanbanboards: Kanbanboard[] = [
    {
      id: 1,
      label: 'Mes Pipelines',
      children: [
        { id: 1, value: 'NEW_OPPORTUNITY', label: 'NOUVEAU', children: [] },
        { id: 2, value: 'NEW_PROP', label: 'PROPOSITION', children: [] },
        { id: 3, value: 'QUALIFIED', label: 'QUALIFIER', children: [] },
        { id: 4, value: 'PROP_GAINED', label: 'GAGNEE', children: [] },
        { id: 5, value: 'PROP_LOST', label: 'PERDUE', children: [] },
        { id: 6, value: 'PROP_STAND_BY', label: 'TEMPORISEE', children: [] },
        { id: 7, value: 'PROP_DECLINED', label: 'REJETEE', children: [] },

      ]
    }
  ];
  public kanbanboardTags: KanbanboardLabel[] = [
    { label: 'High Priority', bgClass: 'bg-amber', textClass: 'text-amber-contrast', },
    { label: 'Blocked', bgClass: 'bg-red', textClass: 'text-red-contrast', },
    { label: 'Approved', bgClass: 'bg-green', textClass: 'text-green-contrast', },
    { label: 'Ready', bgClass: 'bg-cyan', textClass: 'text-cyan-contrast', },
    { label: 'Deployed', bgClass: 'bg-purple', textClass: 'text-purple-contrast', }
  ];

  public tableActions = [
    { label: 'Modifier', icon: this.icEdit, action: ActionEnum.UPDATE, list: true, kanban: true, },
    {
      label: 'Changer le statut',
      icon: this.marKunreadMailbox,
      action: ActionEnum.CHANGE_STATUS,
      list: true,
      kanban: false,
    },
    { label: 'Dupliquer', icon: this.icLibraryBooks, action: ActionEnum.DUPLICATE, list: true, kanban: true, },
    {
      label: 'Attribuer un nouveau devis',
      icon: this.icNoteAdd,
      action: ActionEnum.QUOTE_LINK,
      list: true,
      kanban: true
    },
    { label: 'Supprimer', icon: this.icDelete, action: ActionEnum.DELETE, list: true, kanban: true, }
  ];

  public priorities = [
    {
      value: 'HIGH',
      label: "Haute",
      type: 'link',
      classes: { icon: 'text-orange' },
      bgClass: 'bg-orange',
      textClass: 'text-orange'
    },
    {
      value: 'HIGHEST',
      label: "Plus haute",
      type: 'link',
      classes: { icon: 'text-red' },
      bgClass: 'bg-red',
      textClass: 'text-red'
    },
    {
      value: 'LOW', label: "Faible", type: 'link', classes: { icon: 'text-green' },
      bgClass: 'bg-green',
      textClass: 'text-green'
    },
    {
      value: 'LOWEST', label: "Plus basse", type: 'link', classes: { icon: 'text-primary' },
      bgClass: 'bg-primary',
      textClass: 'text-primary'
    },
  ]

  public paymentMethods = [
    { value: 'CARD', label: "Carte Bancaire" },
    { value: 'CHECK', label: "Chèque bancaire" },
    { value: 'CASH', label: "Espèces" },
    { value: 'BNKTR', label: "Transfert bancaire" },
  ]

  /**************************************************************************************************************
   ********************************************** CUSTOMER DATA *************************************************
   **************************************************************************************************************
   * /

   /** CUSTOMER DATA GRID **/
  public actions: Action[] = [
    { label: 'Modifier', icon: this.icEdit, action: ActionEnum.UPDATE, list: true, grid: true, kanban: false },
    { label: 'Supprimer', icon: this.icDelete, action: ActionEnum.DELETE, list: true, grid: true, kanban: false }
  ];

  /** NOTES DATA TABLE **/
  public noteColumns: TableColumn<any>[] = [
    { label: 'Type', property: 'labelType', type: 'text' },
    { label: 'Nom', property: 'name', type: 'text' },
    { label: 'Descriptiom', property: 'description', type: 'text', cssClasses: ['font-medium'] },
    {
      label: 'Action',
      property: 'delete',
      action: "Supprimer",
      icon: icDelete,
      type: 'action',
      cssClasses: ['text-secondary']
    }
  ];

  public links: Link[] = [
    { label: 'Tous', value: 'ALL', icon: this.icAssignment },
    /*   {label: 'Grands comptes', value: 'BIGACC', icon: this.icAccountBalance},
      {label: 'Particuliers', value: 'PART', icon: this.icPerson},
      {label: 'Entreprises', value: 'STE', icon: this.icBusiness},
      {label: 'Favoris', value: 'FAV', icon: this.icStar} */
  ];

  /** CUSTOMER DATA TABLE **/
  public customerColums: TableColumn<any>[] = [
    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Nom', property: 'name', type: 'text', visible: true },
    {
      label: 'Telephone',
      property: 'phoneNumber',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium']
    },
    { label: 'siren', property: 'siren', type: 'text', visible: true },
    { label: 'siret', property: 'siret', type: 'text', visible: true },
    { label: 'le code postal', property: 'zipCode', type: 'text', visible: true },
    { label: 'Ville', property: 'city', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Pays', property: 'country', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }
  ];

  /** NOTES TYPES **/
  public noteTypes = [
    { label: 'Client', value: 'CUSTOMER' },
    { label: 'Commande', value: 'ORDER' },
    { label: "Devis", value: 'QUOTE' },
    { label: "Facture", value: 'INVOICE' },
    { label: "Stocke", value: 'STOCK' },
    { label: "Abonnement", value: 'ABONNEMENT' },
    { label: "Caisse", value: 'CAISSE' },
  ];

  /** ADDRESS TYPES **/
  public addressTypes = [
    { label: 'Adresse de facturation', value: 'ADF' },
    { label: 'Adresse de livraison', value: 'ADL' },
    { label: "Adresse de l'entreprise", value: 'ADE' },
  ];

  /** CUSTOMER TYPES **/
  public customerTypes = [
    { label: 'Particulier', value: 'PART' },
    { label: 'Entreprise', value: 'STE' }
  ];

  /**************************************************************************************************************
   * ********************************************ACTIVITIES DATA***************************************************
   ***************************************************************************************************************
   * /
   /** ACTIVITIES DATA TABLE **/
  public activitiesTableColumns: TableColumn<any>[] = [
    {
      label: '',
      property: 'done',
      type: 'checkbox',
      cssClasses: ['w-6']
    },
    {
      label: 'Nom',
      property: 'name',
      type: 'text',
      cssClasses: ['text-secondary']
    },
    {
      label: 'Date Debut',
      property: 'startDate',
      type: 'date',
      cssClasses: ['text-secondary']
    },
    {
      label: 'Duree',
      property: 'duration',
      type: 'object',
      cssClasses: ['text-secondary']
    },
    {
      label: 'Description',
      property: 'description',
      type: 'text',
      cssClasses: ['text-secondary']
    },
    {
      label: 'Étiquettes',
      property: 'tags',
      type: 'tag',
      cssClasses: ['text-secondary']
    },
    {
      label: '',
      property: 'starred',
      type: 'button',
      cssClasses: ['text-secondary', 'w-10']
    },
    {
      label: '',
      property: 'menu',
      type: 'button',
      cssClasses: ['text-secondary', 'w-10']
    },
  ];


  public activityDataTableTags: any[] = [
    { name: 'CUSTOMER', label: 'Client', textClass: 'text-green', bgClass: 'bg-green-light', previewClass: 'bg-green' },
    { name: 'ORDER', label: 'Commande', textClass: 'text-cyan', bgClass: 'bg-cyan-light', previewClass: 'bg-cyan' },
    { name: 'INVOICE', label: 'Facture', textClass: 'text-teal', bgClass: 'bg-teal-light', previewClass: 'bg-teal' },
    { name: 'QUOTE', label: 'Devis', textClass: 'text-purple', bgClass: 'bg-purple-light', previewClass: 'bg-purple' },
  ];

  /* *************************************************************************************************************
     ********************************************** Turnover DATA *************************************************
     **************************************************************************************************************
     */

  public turnoverColums: TableColumn<any>[] = [

    { label: 'Case à cocher', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Description', property: 'description', type: 'text', visible: true },
    { label: 'reference', property: 'reference', type: 'text', visible: true },
    { label: 'Montant hors TVA', property: 'amountExcludeVAT', type: 'amount', visible: true },
    { label: 'Montant TTC', property: 'amountIncludeVAT', type: 'amount', visible: true },
    { label: 'Montant TVA', property: 'amountVAT', type: 'amount', visible: true },
    { label: 'Mois', property: 'month', type: 'text', visible: true },
    { label: 'Année', property: 'year', type: 'text', visible: true },
    { label: 'Statut', property: 'statusString', type: 'text', visible: true },
    { label: 'Client', property: 'customerName', type: 'text', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }
  ];
  // for admins
  public turnoverColumsWithMargin: TableColumn<any>[] = [

    { label: 'Case à cocher', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Description', property: 'description', type: 'text', visible: true },
    { label: 'reference', property: 'reference', type: 'text', visible: true },
    { label: 'Montant hors TVA', property: 'amountExcludeVAT', type: 'amount', visible: true },
    { label: 'Montant TTC', property: 'amountIncludeVAT', type: 'amount', visible: true },
    { label: 'Montant TVA', property: 'amountVAT', type: 'amount', visible: true },
    { label: 'Mois', property: 'month', type: 'text', visible: true },
    { label: 'Année', property: 'year', type: 'text', visible: true },
    { label: 'Statut', property: 'statusString', type: 'text', visible: true },
    { label: 'Montant de marge', property: 'marginAmount', type: 'amount', visible: true },
    { label: 'Client', property: 'customerName', type: 'text', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }
  ];

  /** Turnover DATA GRID **/
  public TurnoverActions: Action[] = [
    { label: 'Modifier', icon: this.icEdit, action: ActionEnum.UPDATE, list: true, grid: true, kanban: false },
    {
      // facture changer vers payé ,pour etre status payé

      label: 'Changer le statut',
      icon: this.icCheck, action: ActionEnum.CHANGE_STATUS, list: true, grid: true, kanban: false

    },


    { label: 'Supprimer', icon: this.icDelete, action: ActionEnum.DELETE, list: true, grid: true, kanban: false }
  ];



  /* *************************************************************************************************************
     ********************************************** Expense DATA *************************************************
     **************************************************************************************************************
     */
  public ExpenseColumns: TableColumn<any>[] = [
    { label: 'Date', property: 'expenseDate', type: 'date', visible: true },
    { label: 'Description', property: 'description', type: 'text', visible: true },
    { label: 'Montant', property: 'amount', type: 'amount', visible: true },
    { label: 'Catégorie', property: 'expenseCategoryName', type: 'text', visible: true },
    { label: 'Employé ', property: 'userFirstName', type: 'text', visible: true },
    { label: 'Statut', property: 'expenseStringStatus', type: 'text', visible: true },
    { label: 'Ficher', property: 'fileUrl', type: 'text', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }
  ];

  /** Expense DATA GRID **/
  public ExpenseActions: Action[] = [
    { label: 'Change Status', icon: this.icCheck, action: ActionEnum.CHANGE_STATUS, list: true, grid: true, kanban: false },
    { label: 'Modifier', icon: this.icEdit, action: ActionEnum.UPDATE, list: true, grid: true, kanban: false },
    { label: 'Telecharger', icon: this.icCloudDownload, action: ActionEnum.DOWNLOAD_BDC, list: true, grid: true, kanban: false },
    { label: 'Supprimer', icon: this.icDelete, action: ActionEnum.DELETE, list: true, grid: true, kanban: false }


  ];

  public ExpenseHistoryColumns: TableColumn<any>[] = [
    { label: 'Date', property: 'expenseDate', type: 'date', visible: true },
    { label: 'Description', property: 'description', type: 'text', visible: true },
    { label: 'Montant', property: 'amount', type: 'amount', visible: true },
    { label: 'Catégorie', property: 'expenseCategoryName', type: 'text', visible: true },
    { label: 'Statut', property: 'expenseStringStatus', type: 'text', visible: true },

  ];

  public expenseCategoriesColumns : TableColumn<any>[] = [ 
    { label: 'Nom', property: 'name', type: 'text', visible: true },
    { label: 'Description', property: 'description', type: 'text', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }
  ]


    /**************************************************************************************************************
   * ********************************************DASHBOARD DATA***************************************************
   ***************************************************************************************************************
   * /
   * /** Dashboard DATA TABLE **/

  public DashboardExpenseDetailsColumns: TableColumn<any>[] =  [
    { label: 'Date', property: 'expenseDate', type: 'date', visible: true },
    { label: 'Nom employé', property: 'userName', type: 'text', visible: true },
    { label: 'Montant total', property: 'amount', type: 'amount', visible: true },
    { label: 'Catégorie', property: 'expenseCategoryName', type: 'text', visible: true }
  ];
  public DashboardTurnoversDetailsColumns: TableColumn<any>[]=[
    { label: 'Statut', property: 'status', type: 'badge', visible: true },

    { label: 'reference', property: 'reference', type: 'text', visible: true },
    { label: 'Montant HT', property: 'amountExcludeVAT', type: 'amount', visible: true },
    { label: 'Mois', property: 'month', type: 'text', visible: true },
    { label: 'Année', property: 'year', type: 'text', visible: true },
    { label: 'Montant de marge', property: 'marginAmount', type: 'amount', visible: true },
    { label: 'Nom d\'employé', property: 'userName', type: 'text', visible: true },
  ]

  public DashboardTurnoversDetailsEmployeeColumns: TableColumn<any>[]=[
    { label: 'Statut', property: 'status', type: 'badge', visible: true },
    { label: 'reference', property: 'reference', type: 'text', visible: true },
    { label: 'Montant HT', property: 'amountExcludeVAT', type: 'amount', visible: true },
    { label: 'Mois', property: 'month', type: 'text', visible: true },
    { label: 'Année', property: 'year', type: 'text', visible: true },
  ]
  public DashboardTurnoversDetailsFinancierColumns: TableColumn<any>[]=[
    { label: 'Statut', property: 'status', type: 'badge', visible: true },
    { label: 'reference', property: 'reference', type: 'text', visible: true },
    { label: 'Montant HT', property: 'amountExcludeVAT', type: 'amount', visible: true },
    { label: 'Mois', property: 'month', type: 'text', visible: true },
    { label: 'Année', property: 'year', type: 'text', visible: true },
    { label: 'Nom d\'employé', property: 'userName', type: 'text', visible: true },

  ]

  /* *************************************************************************************************************
     ********************************************** ADMIN DATA *************************************************
     **************************************************************************************************************
     */

  public adminColumns: TableColumn<any>[] = [
    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Nom', property: 'name', type: 'text', visible: true },
    { label: 'Description', property: 'description', type: 'text', visible: true },

  ];
  public adminActions: Action[] = [
    { label: 'Modifier', icon: this.icEdit, action: ActionEnum.UPDATE, list: true, grid: true, kanban: false },
    { label: 'Supprimer', icon: this.icDelete, action: ActionEnum.DELETE, list: true, grid: true, kanban: false },
  ];

    /* *************************************************************************************************************
     ********************************************** MESSAGES DATA *************************************************
     **************************************************************************************************************
     */

     
  public messageTableColumns: TableColumn<any>[] = [
    { label: 'Content', property: 'content', type: 'text', visible: true },
    { label: 'Start Date', property: 'startDate', type: 'date', visible: true },
    { label: 'End Date', property: 'endDate', type: 'date', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }

  ];

  public messageActions: Action[] = [
    { label: 'Modifier', icon: this.icEdit, action : ActionEnum.UPDATE, list: true, grid: true, kanban: false },
    { label: 'Supprimer', icon: this.icDelete, action: ActionEnum.DELETE, list: true, grid: true, kanban: false }
  ];

 /* *************************************************************************************************************
   ********************************************** TASK COMPLITED  DATA *************************************************
   **************************************************************************************************************
   */

  public taskComplitedTableColumns: TableColumn<any>[] = [
    { label: 'Titre', property: 'title', type: 'text', visible: true },
    { label: 'Date de création', property: 'createDate', type: 'date', visible: true },
    { label: 'Description', property: 'description', type: 'text', visible: false },
    { label: 'Créé par', property: 'creatorFullName', type: 'text', visible: true },
    { label: 'Assignée par', property: 'assigneeFullName', type: 'text', visible: true },
   ];
      public taskComplitedActions: Action[] = [
        { label: 'Modifier', icon: this.icEdit, action : ActionEnum.UPDATE, list: true, grid: true, kanban: false },
      ];

      constructor() {
      }

  getData$() {
    return this.data$;
  }

  setData(data: any) {
    this.subject$.next(data);
  }

  /**
   * Icons from @iconify
   *
   * **/

  get icCancel(): any {
    return icCancel;
  }

  get icCloudDownload(): any {
    return icCloudDownload;
  }

  get icCheck(): any {
    return icCheck;
  }

  get icPayment(): any {
    return icPayment;
  }

  get icSend(): any {
    return icSend;
  }

  get icAssignment(): any {
    return icAssignment;
  }

  get icBusiness(): any {
    return icBusiness;
  }

  get icPerson(): any {
    return icPerson;
  }

  get icAccountBalance(): any {
    return icAccountBalance;
  }

  get icStar(): any {
    return icStar;
  }

  get icEdit(): any {
    return icEdit;
  }

  get icInsertDriveFile(): any {
    return icInsertDriveFile;
  }

  get icDelete(): any {
    return icDelete;
  }

  get marKunreadMailbox(): any {
    return marKunreadMailbox;
  }

  get icAddAlarm(): any {
    return icAddAlarm;
  }

  get icLibraryBooks(): any {
    return icLibraryBooks;
  }

  get icNoteAdd(): any {
    return icNoteAdd;
  }

  get icAssessment(): any {
    return icAssessment;
  }

  get icMessage(): any {
    return icMessage;
  }

  get icDateRange(): any {
    return icDateRange;
  }

  get icLabel(): any {
    return icDateRange;
  }


}
