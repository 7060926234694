import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Link} from "../../interfaces/link.interface";
import icFilterList from "@iconify/icons-ic/twotone-filter-list";
import icCloudDownload from "@iconify/icons-ic/twotone-cloud-download";
import icPersonAdd from "@iconify/icons-ic/twotone-person-add";
import icPeople from "@iconify/icons-ic/twotone-people";
import icSearch from '@iconify/icons-ic/twotone-search';
import {trackById} from '../../utils/track-by';
import {scaleIn400ms} from "../../../../../@vex/animations/scale-in.animation";
import {fadeInRight400ms} from "../../../../../@vex/animations/fade-in-right.animation";
import {stagger40ms} from "../data-table/animations/stagger.animation";
import {fadeInUp400ms} from "../data-table/animations/fade-in-up.animation";
import {scaleFadeIn400ms} from "../../../../../@vex/animations/scale-fade-in.animation";
import {Action} from "../../interfaces/action.interface";
import {UntypedFormControl} from "@angular/forms";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
@UntilDestroy()
@Component({
  selector: 'vought-app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    fadeInUp400ms,
    scaleFadeIn400ms
  ]
})
export class DataGridComponent implements OnInit, OnChanges {

  @Output() actionEvent = new EventEmitter<any>();
  @Output() filterEvent = new EventEmitter<any>();
  @Input() title: string;
  @Input() links: Link[] = [];
  @Input() elements: any[];
  filteredElements: any[];
  @Input() addMessage: string= "AJOUTER CLIENT";
  @Input() actions: Action[];
  searchCtrl = new UntypedFormControl();
  search: string

  trackById = trackById;


  constructor() {
  }

  ngOnInit() {
    this.filteredElements = this.elements;

  }

  ngOnChanges(): void {
    this.filteredElements = this.elements;
    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  onFilterChange(value: string) {
    value = value.trim();
    value = value.toLowerCase();
    this.search = value;
    this.filteredElements = (this.search == null || this.search === '') ? this.elements :
      this.elements.filter(obj => this.filterPredicate(obj, this.search));
  }

  filterPredicate = (data, filter) => {
    const dataStr = Object.keys(data).reduce((currentTerm, key) => {
      return currentTerm + data[key] + '◬';
    }, '').toLowerCase();
    const transformedFilter = filter.trim().toLowerCase();
    return dataStr.indexOf(transformedFilter) != -1;
  };


  handleEvent(event: any) {
    this.actionEvent.emit(event);
  }

  addElement() {
    this.actionEvent.emit({
      action: "create"
    });
  }

  toggleStar(event: any) {
    const element = event.data;
    if (element) {
      element.starred = !element.starred;
    }
    this.actionEvent.emit({
      data: element,
      action: "toggle-star",
    });
  }

  get icPeople(): any {
    return icPeople;
  }

  get icSearch(): any {
    return icSearch;
  }

  get icPersonAdd(): any {
    return icPersonAdd;
  }

  get icCloudDownload(): any {
    return icCloudDownload;
  }

  get icFilterList(): any {
    return icFilterList;
  }


  filterData(filter) {
    this.filterEvent.emit({
      filter: filter,
    });
  }
}
